article {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.submit-container {
  text-align: center;
  margin: 20px;
}
